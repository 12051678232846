import { ref, update } from "firebase/database";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { GlobalSettings } from "../../../Widget/src/interfaces/UserPresence";
import { Routes } from "../Constants";
import { getDb } from "../firebase";

interface QueueModeDropdownProps {
	path: string;
	settings?: GlobalSettings;
}
enum QueueState {
	off,
	auto,
	on,
}

const QueueModeDropdown: React.FC<QueueModeDropdownProps> = ({ path, settings }) => {
	const db = getDb(path === Routes.dashboardStaging);
	const changeQueueMode = (status: string) => {
		const settingsRef = ref(db, `/settings`);
		update(settingsRef, {
			queueState: status.includes("on") || status === "maintenance" ? 2 : status.includes("auto") ? 1 : 0,
			heading: settings?.heading ?? "",
			body: settings?.body ?? "",
			paused: status.includes("paused"),
			maintenance: {
				...settings?.maintenance,
				on: status === "maintenance",
			},
			maxLetIn: settings?.maxLetIn,
			internetLimit: settings?.internetLimit,
			clientQueuePercent: settings?.clientQueuePercent,
			proactiveQueuePercent: settings?.proactiveQueuePercent,
		})
			.then(() => {
				// nothing to do
			})
			.catch((err) => {
				console.log(err);
				alert("Something went wrong. Check the console for more info.");
			});
	};
	return (
		<Dropdown className="dataview-dropdown">
			<Dropdown.Toggle
				variant="light"
				title="Select View"
				className={`trigger queue-status-${
					settings?.maintenance.on ? "maintenance" : QueueState[settings?.queueState ?? 0]
				} ${settings?.paused ? "paused" : ""}`}
			>
				<i
					className={`fas m-right fa-${
						settings?.maintenance.on
							? "wrench"
							: settings?.queueState === 1
							? settings.paused
								? "pause"
								: "play"
							: settings?.queueState === 2
							? settings.paused
								? "pause"
								: "play"
							: "stop"
					}`}
				></i>
				<b>{`${
					settings?.maintenance.on
						? "Maintenance"
						: settings?.queueState === 1
						? `Auto`
						: settings?.queueState === 2
						? "On"
						: "Off"
				}${settings?.paused || settings?.maintenance.on ? " + Paused" : ""}`}</b>
			</Dropdown.Toggle>
			<Dropdown.Menu align="end" className="dropdown-menu-sm menu">
				<Dropdown.Item as="button" className="item py-1" onClick={() => changeQueueMode("off")}>
					<div className="p queue-status-off">
						<h4>
							<i className="fas fa-fw m-right fa-stop"></i>
							Off
						</h4>
						<h6 style={{ marginLeft: "35px" }}>No queue protection</h6>
					</div>
				</Dropdown.Item>
				<Dropdown.Item as="button" className="item py-1" onClick={() => changeQueueMode("on")}>
					<div className="p queue-status-on">
						<h4>
							<i className="fas fa-fw m-right fa-play"></i>
							On
						</h4>
						<h6 style={{ marginLeft: "35px" }}>Everyone passes through queue</h6>
					</div>
				</Dropdown.Item>
				<Dropdown.Item as="button" className="item py-1" onClick={() => changeQueueMode("on-paused")}>
					<div className="p queue-status-on paused">
						<h4>
							<i className="fas fa-fw m-right fa-pause"></i>
							On + Paused
						</h4>
						<h6 style={{ marginLeft: "35px" }}>Everyone held in queue</h6>
					</div>
				</Dropdown.Item>
				<Dropdown.Item as="button" className="item py-1" onClick={() => changeQueueMode("auto")}>
					<div className="p queue-status-auto">
						<h4>
							<i className="fas fa-fw m-right fa-play"></i>
							Auto
						</h4>
						<h6 style={{ marginLeft: "35px" }}>Using logic to selectively send to queue</h6>
					</div>
				</Dropdown.Item>
				<Dropdown.Item as="button" className="item py-1" onClick={() => changeQueueMode("auto-paused")}>
					<div className="p queue-status-auto paused">
						<h4>
							<i className="fas fa-fw m-right fa-pause"></i>
							Auto + Paused
						</h4>
						<h6 style={{ marginLeft: "35px" }}>Those sent to queue are held</h6>
					</div>
				</Dropdown.Item>
				<Dropdown.Item as="button" className="item py-1" onClick={() => changeQueueMode("maintenance")}>
					<div className="p queue-status-maintenance">
						<h4>
							<i className="fas fa-fw m-right fa-wrench"></i>
							Maintenance + Paused
						</h4>
						<h6 style={{ marginLeft: "35px" }}>Everyone held on maintenance screen</h6>
					</div>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default QueueModeDropdown;
