import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyBZtvoD9zdmFAz8Rk_DSm1a_RM15q3XNuM",
	authDomain: "brushfire-widget-source.firebaseapp.com",
	databaseURL: "https://brushfire-widget-source-default-rtdb.firebaseio.com",
	projectId: "brushfire-widget-source",
	storageBucket: "brushfire-widget-source.appspot.com",
	messagingSenderId: "75241792522",
	appId: "1:75241792522:web:f8255a1207d3949b452bf3",
	measurementId: "G-WMF6S4BXJP",
};

const fb = initializeApp(firebaseConfig);

export const getDb = (isStaging: boolean) => {
	if (isStaging) {
		return getDatabase(fb, "https://entry-queue-staging.firebaseio.com"); // Staging rtdb
	}
	return getDatabase(fb); // Default
}

export default fb;
